.ethical-ad img {
  @apply w-full;
}

.ethical-ad {
  @apply w-full object-cover;
}

.ethical-ad a {
  @apply px-1 w-full object-cover justify-center items-center flex flex-col; 
}

.ethical-ad a figure {
  @apply object-contain w-full h-full;
}

.ethical-ad a figure img {
  @apply object-cover w-full h-full;
}

.ethical-ad a figure figcaption {
  @apply text-3xs px-4 py-1 tablet:px-0 text-black text-center bg-transparent;
}

.white-padded-ad-container div {
  @apply bg-white pb-2 px-2;
}

.article-ad-container div {
  @apply bg-gray-100 pb-2 px-2;
}

.takeover-container img {
  @apply absolute top-0 w-full h-full object-contain;
}

.etc-banner-top .ethical-ad a {
  text-decoration: none;
  padding-left: 0.9rem;
  padding-right: 0.9rem;
  padding-top: 0.375rem;
  padding-bottom: 0.375rem;
  color: rgba(255, 255, 255, 1);
  background-color: rgba(227, 6, 19, 1);
  border-color: rgba(227, 6, 19, 1);
  border-width: 1px;
  border-style: solid;
  border-radius: 9999px;
  white-space: nowrap;
  display: block;
}

.etc-banner-top .ethical-ad a {
  margin-left: 1em;
}

.etc-banner-top .ethical-ad {
  display: flex;
  justify-content: center;
  position: relative;
  align-items: center;
}

.popup-ad {
  @apply flex flex-col justify-between;
}

.popup-ad > .popup-content {
  @apply flex flex-col tablet:flex-row grow gap-2 tablet:gap-5 justify-between;
}

.popup-ad > .popup-content > .popup-text {
  @apply mb-4 tablet:basis-96 tablet:shrink-0;
}

.popup-ad > .popup-content > .popup-text > .popup-heading {
  @apply mb-4 font-semibold font-stag-serif text-6xl leading-[3.5rem];
}
.popup-ad > .popup-content > .popup-text > .popup-text-paragraph {
  @apply prose prose-lg;
}

.popup-ad > .popup-content > .popup-image-container {
  @apply grow self-center;
}

.popup-ad > .popup-content > .popup-image-container img {
  @apply w-full;
}

.popup-ad > .popup-buttons {
  @apply flex items-stretch items-center gap-2 mb-2 mt-8;
}

.popup-ad > .popup-buttons > .popup-button {
  @apply block grow text-center tablet:grow-0 w-fit font-semibold border rounded-full py-2 px-4 text-sm;
  @apply cursor-pointer active:bg-black active:text-white active:border-black hover:border-black hover:bg-black hover:text-white focus:outline focus:outline-black disabled:outline-none disabled:cursor-not-allowed disabled:opacity-60;
}

.popup-ad > .popup-buttons > .popup-accept {
  @apply bg-green border-green text-white disabled:bg-green disabled:border-green disabled:text-white;
}

.popup-ad > .popup-buttons > .popup-decline {
  @apply border-green disabled:border-green;
}
